export default class ComparisonScroller extends HTMLElement {
  constructor() {
    super();
    this.scrollTimeout = null;
    this.fontSize = Number(
      window
        .getComputedStyle(document.body)
        .getPropertyValue("font-size")
        .match(/\d+/)[0]
    );
  }

  wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  connectedCallback() {
    this.scrollerBottom = this.querySelector("#scrollerBottom");
    this.comparisonContainer = this.querySelector("#comparison");
    this.tbody = this.querySelector("tbody");
    this.table = this.querySelector("table");
    this.navBar = document.querySelector("nav-bar");
    this.navBarHeight = this.navBar.getBoundingClientRect().height;
    this.arrowForward = this.querySelector("button[data-for='forwards']");
    this.arrowBackward = this.querySelector("button[data-for='backwards']");
    this.main = this.querySelector("main");
    this.scrollerTop = this.querySelector("#scrollerTop");
    this.scrollerTopInitialHeight = Number(
      this.scrollerTop.getBoundingClientRect().height
    );
    this.scrollerBottomInitialHeight = Number(
      this.scrollerBottom.getBoundingClientRect().height
    );

    this.scrollerBottom.style.width =
      this.tbody.getBoundingClientRect().width + "px";
    this.table.parentElement.addEventListener(
      "scroll",
      this._debouncedScroll.bind(this)
    );
    this.scrollerBottom.parentElement.addEventListener(
      "scroll",
      this._handleScrollerBottomScroll.bind(this)
    );
    this.arrowBackward.addEventListener(
      "click",
      this._scrollBackwards.bind(this)
    );
    this.arrowForward.addEventListener(
      "click",
      this._scrollForwards.bind(this)
    );

    window.addEventListener("scroll", () => {
      // clearTimeout(this.mainTimeout);
      // this.mainTimeout = setTimeout(() => {
      //   this.scroll();
      // }, 0);
      this.scroll();
    });
    this.scroll();
    window.addEventListener("resize", () => {
      this.scrollerBottom.style.width =
        this.tbody.getBoundingClientRect().width + "px";
      this.scrollerTop.style.width =
        this.tbody.getBoundingClientRect().width + "px";
      window.scrollTo(0, window.scrollY + 1);
    });
  }

  disconnectedCallback() {
    this.table.parentElement.removeEventListener(
      "scroll",
      this._debouncedScroll.bind(this)
    );
    this.scrollerBottom.parentElement.removeEventListener(
      "scroll",
      this._handleScrollerBottomScroll.bind(this)
    );
    this.arrowBackward.removeEventListener(
      "click",
      this._scrollBackwards.bind(this)
    );
    this.arrowForward.removeEventListener(
      "click",
      this._scrollForwards.bind(this)
    );
  }

  _debouncedScroll() {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.scrollerBottom.style.opacity = 1;
      if (this.scrollerTop.classList.contains("active")) {
        this.scrollerTop.style.opacity = 1;
      }
    }, 200);

    if (this.scrollerBottom.style.position !== "relative") {
      this.scrollerBottom.style.opacity = 0;
      this.scrollerBottom.style.transform = `translate3d(-${this.table.parentElement.scrollLeft}px, 0, 0)`;
    }

    if (this.scrollerTop.dataset.shouldTransform !== "false") {
      this.scrollerTop.style.opacity = 0;
      this.scrollerTop.style.transform = `translate3d(-${this.table.parentElement.scrollLeft}px, 0, 0)`;
    }
  }

  _handleScrollerBottomScroll() {
    const scrolled = this.scrollerBottom.parentElement.scrollLeft > 0;
    const heads = this.querySelectorAll("th > div");
    heads.forEach((head) => {
      if (scrolled) {
        head.classList.add("!bg-white", "bg-opacity-white", "shadow-md");
      } else {
        head.classList.remove("!bg-white", "bg-opacity-white", "shadow-md");
      }
    });
    this.checkAndToggleArrows(true);
  }

  async scroll() {
    const tableBodyRect = this.tbody.getBoundingClientRect();
    this.scrollerTop.style.width = tableBodyRect.width + "px";
    this.querySelector("#scrollerBottom > div").style.width =
      tableBodyRect.width + "px";

    const navBarHeight = this.navBar.getBoundingClientRect().height;
    if (
      this.comparisonContainer.getBoundingClientRect().top < navBarHeight &&
      this.comparisonContainer.getBoundingClientRect().bottom >
        window.innerHeight
    ) {
      this.scrollerTop.style.position = "fixed";
      this.scrollerTop.style.top = navBarHeight + "px";
      this.scrollerTop.style.opacity = 1;
      this.scrollerTop.pointerEvents = "auto";
      this.tbody.style.paddingTop =
        this.scrollerTop.getBoundingClientRect().height + "px";
      this.scrollerTop.dataset.shouldTransform = true;
      this.scrollerTop.style.transform = `translate3d(-${this.table.parentElement.scrollLeft}px, 0, 0)`;
      this.scrollerTop.classList.add("active");
      this.scrollerTop.style.pointerEvents = "auto";

      this.scrollerBottom.classList.add("active");

      this.checkAndToggleArrows(true);

      this.scrollerBottom.style.position = "fixed";
      this.scrollerBottom.style.bottom = "0";
      this.scrollerBottom.dataset.shouldTransform = true;
      this.scrollerBottom.style.transform = `translate3d(-${this.table.parentElement.scrollLeft}px, 0, 0)`;
      this.tbody.style.paddingBottom = this.scrollerBottomInitialHeight + "px";
    } else if (
      this.comparisonContainer.getBoundingClientRect().top > navBarHeight
    ) {
      this.scrollerTop.style.position = "inherit";
      this.scrollerTop.style.transform = "translate3d(0, 0, 0)";
      this.scrollerTop.dataset.shouldTransform = false;
      this.scrollerTop.classList.remove("active");
      this.scrollerBottom.classList.remove("active");
      this.tbody.style.paddingTop = 0;
      this.scrollerTop.style.top = 0;
      this.checkAndToggleArrows(false);
      this.scrollerBottom.style.position = "relative";
      this.scrollerBottom.style.transform = "translate3d(0, 0, 0)";
      this.scrollerBottom.dataset.shouldTransform = false;
      this.tbody.style.paddingBottom = 0;
      this.scrollerTop.style.opacity = 1;
    } else if (
      this.comparisonContainer.getBoundingClientRect().bottom <
      window.innerHeight + this.scrollerBottom.getBoundingClientRect().height
    ) {
      this.scrollerBottom.style.position = "relative";
      this.scrollerBottom.style.transform = "translate3d(0, 0, 0)";
      this.tbody.style.paddingBottom = 0;
      // this.scrollerTop.style.opacity = 0;
      // this.scrollerTop.pointerEvents = "none";
      // this.scrollerTop.classList.remove("active");
      this.scrollerBottom.classList.remove("active");
    }
    
    if (
      this.comparisonContainer.getBoundingClientRect().bottom < (this.scrollerTop.getBoundingClientRect().height * 2)
    ) {
      this.scrollerTop.style.opacity = 0;
      this.scrollerTop.pointerEvents = "none";
      this.scrollerTop.classList.remove("active");
      this.scrollerTop.style.pointerEvents = "none";
    }
    
    if (
      this.scrollerBottom.getBoundingClientRect().top <
      window.innerHeight / 2
    ) {
      this.checkAndToggleArrows(false);
    }

    // window.requestAnimationFrame(async () => {
    //   await this.scroll();
    // });
  }

  checkAndToggleArrows(value) {
    if (this.comparisonContainer.scrollLeft === 0) {
      this.arrowBackward.style.display = "none";
    } else if (value) {
      this.arrowBackward.style.display = "block";
    } else {
      this.arrowBackward.style.display = "none";
    }
    if (
      this.comparisonContainer.scrollLeft ===
      this.comparisonContainer.scrollWidth -
        this.comparisonContainer.clientWidth
    ) {
      this.arrowForward.style.display = "none";
    } else if (value) {
      this.arrowForward.style.display = "block";
    } else {
      this.arrowForward.style.display = "none";
    }
  }

  _scrollBackwards() {
    let columnWidth = this.querySelector("td").getBoundingClientRect().width;
    this.comparisonContainer.scrollLeft -=
      window.innerWidth <= 430
        ? columnWidth + this.fontSize
        : columnWidth + this.fontSize * 4;
    this.checkAndToggleArrows();
  }

  _scrollForwards() {
    let columnWidth = this.querySelector("td").getBoundingClientRect().width;
    this.comparisonContainer.scrollLeft +=
      window.innerWidth <= 430 ? columnWidth : columnWidth + this.fontSize * 4;
    this.checkAndToggleArrows();
  }
}
