export default class PopUp extends HTMLElement {
  constructor() {
    super();
    this._handleButtonClick = this._handleButtonClick.bind(this);
    this._handleOutsideClick = this._handleOutsideClick.bind(this);
    this._handleCloseClick = this._handleCloseClick.bind(this);
  }

  connectedCallback() {
    this.innerButton = this.querySelector("button");
    this.innerButton.addEventListener("click", this._handleButtonClick);
    this.popupContent = this.querySelector(`*[data-for="${this.dataset.id}"]`);
    this.popupContent = this.popupContent.cloneNode(true);
    this.popupContent.classList.remove("hidden");
  }

  _handleButtonClick() {
    document.body.style.overflow = "hidden";
    const element = document.createElement("div");
    element.classList.add("popup");

    // Add close button
    const closeButton = document.createElement("button");
      closeButton.classList.add("popup-close");
      closeButton.innerHTML = `
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
            />
        </svg>
      `;
      closeButton.addEventListener("click", this._handleCloseClick);
    
      this.popupContent.appendChild(closeButton);
      element.appendChild(this.popupContent);
      

    // Append to body
    document.body.appendChild(element);

    // Add event listener to close popup when clicking outside
    element.addEventListener("click", this._handleOutsideClick);

    // Stop propagation to prevent closing when clicking inside popup content
    this.popupContent.addEventListener("click", (e) => e.stopPropagation());
  }

  _handleCloseClick() {
    this._removePopup();
  }

  _handleOutsideClick() {
    this._removePopup();
  }

  _removePopup() {
    document.body.style.overflow = "unset";
    const popupElement = document.querySelector(".popup");
    if (popupElement) {
      popupElement.removeEventListener("click", this._handleOutsideClick);
      popupElement.remove();
    }
  }

  disconnectedCallback() {
    if (this.innerButton) {
      this.innerButton.removeEventListener("click", this._handleButtonClick);
    }
  }
}

