export default class Search extends HTMLElement {
  inputElement;
  listElement;
  submitButtonElement;
  spinnerElement;
  navElement;
  debounceTimeout = null;

  jsonURL =
    process.env.NODE_ENV === "development"
      ? "/api/busqueda/"
      : "/api/busqueda/index.json";

  json = null;

  results = [];

  showingListClassName =
    "bg-white absolute w-full p-0 shadow-lg rounded-lg flex flex-col gap-4 z-10 overflow-y-scroll";

  constructor() {
    super();
  }

  async fetchJSON(url) {
    if (this.json) {
      return this.json;
    }
    return fetch(url).then((response) => response.json());
  }

  connectedCallback() {
    this.inputElement = this.querySelector("input");
    this.listElement = this.querySelector("ul");
    this.submitButtonElement = this.querySelector("button");
    this.spinnerElement = this.querySelector(".spinner");
    this.navElement = document.querySelector("nav-bar");
    this._listenEvents();
    this.listElement.className = this.showingListClassName;
  }

  _listenEvents() {
    this.inputElement.addEventListener(
      "input",
      this._debounce(this._handleInput.bind(this), 300)
    );
    this.submitButtonElement.addEventListener(
      "click",
      this._handleSubmit.bind(this)
    );
    document.addEventListener("click", this._clearList.bind(this));
    this.inputElement.addEventListener("focus", this._handleFocus.bind(this));
  }

  _unlistenEvents() {
    this.inputElement.removeEventListener(
      "input",
      this._debounce(this._handleInput.bind(this), 300)
    );
    this.submitButtonElement.removeEventListener(
      "click",
      this._handleSubmit.bind(this)
    );
    this.inputElement.removeEventListener(
      "focus",
      this._handleFocus.bind(this)
    );
    document.removeEventListener("click", this._clearList.bind(this));
  }

  disconnectedCallback() {
    this._unlistenEvents();
  }

  _debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  _handleFocus() {
    const { top } = this.inputElement.getBoundingClientRect();
    const navHeight = this.navElement.offsetHeight;
    // window.scrollTo({ top: top - navHeight, behavior: "smooth" });
    this._handleInput({ target: { value: "a" } });
  }

  _renderList() {
    const listTemplate = `
      ${this.results
        .map(
          (item) => `
        <li>
          <a class="text-dark flex justify-between items-center cursor-pointer font-bold" href="${item.slug}">
              <span>${item.title}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style="transform: rotate(180deg)"
                fill="black"
              >
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
              </svg>
          </a>
        </li>
      `
        )
        .join("")}
        ${
          this.results.length === 0
            ? `
          <li>
            <p class="text-white text-xl">No se encontraron resultados</p>
          </li>
        `
            : ""
        }
        `;
    // this.listElement.style.height = "30vh";
    this.listElement.innerHTML = listTemplate;
  }

  _clearList(event) {
    if (!this.contains(event.target) || event.target === null) {
      this.listElement.innerHTML = "";
      this.listElement.classList.remove("active");
    }
  }

  async _handleInput(event) {
    this.listElement.classList.add("active");
    const query = event.target.value;

    if (!query) {
      this._clearList({ target: null });
      return;
    }

    // if (query.length < 3 && !this.json) {
    //   return;
    // }

    if (!this.json) {
      this.listElement.innerHTML = "Cargando...";
      this.spinnerElement.style.display = "block";
      this.submitButtonElement.querySelector("img").style.display = "none";
      try {
        this.json = await this.fetchJSON(this.jsonURL);
      } catch (err) {
        alert(
          "Lo sentimos. Tuvimos un error procesando su peticion. Por favor, intente mas tarde."
        );
      } finally {
        this.submitButtonElement.querySelector("img").style.display = "block";
        this.spinnerElement.style.display = "none";
      }
    }
    this.results = this.json.filter((item) => {
      if (!query) {
        return true;
      }
      const normalizedQuery = query
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      const normalizedTitle = item.title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      return normalizedTitle.includes(normalizedQuery);
    });
    this._renderList();
  }

  _handleSubmit(event) {
    event.preventDefault();
    this._handleInput({ target: { value: "a" } });
  }
}
