import Splide from '@splidejs/splide';

export default class SplideComponent extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.splideConfig = JSON.parse(this.dataset.config)
        this.id = this.dataset.id;
        this.classList.add("splide-container");
        this._init();
    }
    
    _init() {
        this.splideInstance = new Splide(this.querySelector('.splide'), this.splideConfig).mount();
    }
}