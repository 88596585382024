export default class Toast extends HTMLElement {
    static instance = null;

    static showToast(message, options = {}) {
        if (!Toast.instance) {
            Toast.instance = document.createElement('toast-component');
            document.body.appendChild(Toast.instance);
        }
        Toast.instance.show(message, options);
    }

    connectedCallback() {
        this.attachShadow({ mode: "open" });
        this._render();
    }

    _render() {
        const style = document.createElement('style');
        style.textContent = `
            :host {
                position: fixed;
                bottom: 10rem;
                left: 50%;
                width: 90%;
                transform: translateX(-50%);
                background-color: #333;
                color: white;
                padding: 10px 20px;
                border-radius: 5px;
                box-shadow: 0 0 10px rgba(0,0,0,0.1);
                display: flex;
                align-items: center;
                justify-content: space-between;
                opacity: 0;
                transition: opacity 0.3s, bottom 0.3s;
                z-index: 10000;
                display: flex;
            }
            
            .toast-container {
                width: 100%;
                display: flex;

            }
            .toast-container p {
                width: 100%;
                overflow: hidden;
                margin-top: 1.2rem;
                margin-bottom: 1rem;
            }
            .close {
                position: absolute;
                right: 0.5rem;
                top: 0.5rem;
                background: none;
                border: none;
                color: white;
                font-size: 1.5rem;
                cursor: pointer;
                width: 10%;
            }
        `;

        this.shadowRoot.appendChild(style);

        this.container = document.createElement('div');
        this.container.className = 'toast-container';

        this.messageElement = document.createElement('p');
        this.messageElement.className = 'message';

        this.closeButton = document.createElement('button');
        this.closeButton.className = 'close';
        this.closeButton.innerHTML = '&times;';
        this.closeButton.onclick = () => this._hide();

        this.container.appendChild(this.messageElement);
        this.container.appendChild(this.closeButton);
        this.shadowRoot.appendChild(this.container);
    }

    show(message, { duration = 3000, cancellable = true } = {}) {
        this.messageElement.textContent = message;
        this.closeButton.style.display = cancellable ? 'flex' : 'none';

        this.style.opacity = '1';
        this.style.bottom = '1rem';

        if (this.timeoutId) clearTimeout(this.timeoutId);

        if (duration > 0) {
            this.timeoutId = setTimeout(() => {
                this._hide();
            }, duration);
        }
    }

    _hide() {
        this.style.opacity = '0';
        this.style.bottom = '0px';
        if (this.timeoutId) clearTimeout(this.timeoutId);
    }
}

window.Toast = Toast;