export default class SeeMore extends HTMLElement {
  constructor() {
    super();
  }

  open = false;

  connectedCallback() {
    if (window.innerWidth > 768) {
      return;
    }
    this.realHeight = this.querySelector("p").getBoundingClientRect().height;
    this.querySelector("p").style.overflow = "hidden";
    this.querySelector("p").style.maxHeight = "5rem";
    this._appendButton();
    const style = document.createElement("style");
    style.textContent = `
              see-more button {
                font-size: 1rem;
                font-weight: 600;
                color: var(--text-primary);
                cursor: pointer;
                display: block;
                margin-top: 1rem;
                border: none;
                background: none;
                width: 100%;
                text-align: center;
              }
      
              see-more p {
                  transition: max-height 0.3s;
                  position: relative;
                  padding: 0;
              }
      
              see-more p.closed::after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  height: 30px; /* This is the height of the fade-out effect */
                  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); /* Adjust colors and direction as needed */
              }
            `;
    this.appendChild(style);
  }

  _appendButton() {
    const button = document.createElement("button");
    button.textContent = "Ver más";
    this.querySelector("p").classList.add("closed");
    button.addEventListener("click", () => {
      if (!this.open) {
        this.querySelector("p").style.maxHeight = "none";
        this.querySelector("p").classList.remove("closed");
        button.textContent = "Ver menos";
        this.open = true;
      } else {
        this.querySelector("p").style.maxHeight = 5 + "rem";
        this.querySelector("p").classList.add("closed");
        button.textContent = "Ver más";
        this.open = false;
      }
    });
    this.appendChild(button);
  }
}
