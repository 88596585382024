// @ts-nocheck
import Nav from "./components/Nav.js";
import Search from "./components/Search.js";
import SeeMore from "./components/SeeMore.js";
import ComparisonScroller from "./components/ComparisonScroller.js";
import SplideComponent from "./components/SplideComponent.js";
import emailjs from "@emailjs/browser";
import Toast from "./components/Toast.js";
import PopUp from "./components/PopUp.js";

customElements.define("search-component", Search);
customElements.define("see-more", SeeMore);
customElements.define("nav-bar", Nav);
customElements.define("comparison-scroller", ComparisonScroller);
customElements.define("splide-component", SplideComponent);
customElements.define("toast-component", Toast)
customElements.define("pop-up", PopUp)


emailjs.init({
    publicKey: "OImiSsVqZ2MndSVZU",
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "app",
      // Allow 1 request per 10s
      throttle: 10000,
    },
  });

// Contact form
if (document.querySelector("#contact-form")) {
  document.querySelector("#contact-form").addEventListener("submit", (e) => {
    e.preventDefault();
    document.querySelector('button[type="submit"]').innerHTML = 'Cargando...'
    emailjs
      .sendForm("service_vq9omr9", "template_vud0hx7", "#contact-form")
      .then(
        (response) => {
          const message = "Correo enviado correctamente. Nos pondremos en contacto en breves"
          Toast.showToast(message);
          document.querySelector('button[type="submit"]').innerHTML = 'Enviado'
          e.target.classList.add('disabled');
          const span = document.createElement('span');
          span.innerHTML = message;
          span.classList.add("font-bold")
          e.target.appendChild(span)
        },
        (error) => {
            Toast.showToast("Problemas enviando correo. Intentelo mas tarde");
        }
      );
  });
}


// Newsletter form
if (document.querySelector("#newsletter-form")) {
    document.querySelector("#newsletter-form").addEventListener("submit", (e) => {
      e.preventDefault();
      document.querySelector('button[type="submit"]').innerHTML = 'Cargando...'
      emailjs
        .sendForm("service_vq9omr9", "template_gdwi83r", "#newsletter-form")
        .then(
          (response) => {
            const message = "Se ha suscrito correctamente a nuestra newsletter!"
            Toast.showToast(message);
            document.querySelector('button[type="submit"]').innerHTML = 'Enviado'
            e.target.classList.add('disabled');
            const span = document.createElement('span');
            span.innerHTML = message;
            span.classList.add("font-bold")
            e.target.appendChild(span)
          },
          (error) => {
              Toast.showToast("Problemas suscribiendo a newsletter. Intentelo mas tarde");
          }
        );
    });
  }



// // Regsiter with for comparison table
// const table = document.querySelector("table.comparison");
// if (table) {
//   const td = table.querySelector("td");
//   document.documentElement.style.setProperty(
//     "--cell-width",
//     td?.getBoundingClientRect().width + "px"
//   );
//   document.documentElement.style.setProperty(
//     "--rows-amount",
//     table.dataset.amount
//   );
//   document.documentElement.style.setProperty(
//     "--table-width",
//     table.getBoundingClientRect().width + "px"
//   );
// }
