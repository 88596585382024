export default class NavBar extends HTMLElement {
  constructor() {
    super();
  }

  height = null;

  connectedCallback() {
    const resourcePathname = window.location.pathname.split("/")[0];

    // Get the current path
    const currentPath = window.location.pathname;

    // Find all links with the class "nav-link"
    const navLinks = document.querySelectorAll("nav-bar a");

    // Loop through each link
    navLinks.forEach(function (link) {
      // Get the link's href attribute
      const linkHref = link.getAttribute("href");

      // Check if the link's href matches the current path
      if (linkHref === currentPath) {
        // Add the "active" class to the matching link
        link.classList.add("active");
      }
    });

    this.querySelector(".menu-toggle")?.addEventListener(
      "click",
      this.onToggle.bind(this)
    );
    document.documentElement.style.setProperty(
      "--nav-height",
      this.getBoundingClientRect().height + "px"
    );
    this.onScroll();
    window.addEventListener("scroll", this.onScroll.bind(this));
  }

  onToggle() {
    this.classList.toggle("active");
  }

  onScroll() {
    const rootFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    if (window.scrollY > rootFontSize * 1.5) {
      this.classList.add("scrolled");
    } else {
      this.classList.remove("scrolled");
    }
    document.body.style.setProperty(
      "--nav-height",
      this.getBoundingClientRect().height + "px"
    );
  }

  disconnectedCallback() {
    window.removeEventListener("scroll", this.onScroll);
  }
}
